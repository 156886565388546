import React from 'react';
import style from './style.module.scss';
import circle from './crcle.svg';

export default function Bubbles() {
    return (
        <div className={style.container}>
            <img src={circle} alt={''} className={style.circle1}/>
            <img src={circle} alt={''} className={style.circle2}/>
            <img src={circle} alt={''} className={style.circle3}/>
            <img src={circle} alt={''} className={style.circle4}/>
            <img src={circle} alt={''} className={style.circle5}/>
            <img src={circle} alt={''} className={style.circle6}/>
            <img src={circle} alt={''} className={style.circle7}/>
            <img src={circle} alt={''} className={style.circle8}/>
            <img src={circle} alt={''} className={style.circle9}/>
            <img src={circle} alt={''} className={style.circle10}/>
            <img src={circle} alt={''} className={style.circle11}/>
            <img src={circle} alt={''} className={style.circle12}/>
            <img src={circle} alt={''} className={style.circle13}/>
            <img src={circle} alt={''} className={style.circle14}/>
            <img src={circle} alt={''} className={style.circle15}/>
            <img src={circle} alt={''} className={style.circle16}/>
            <img src={circle} alt={''} className={style.circle19}/>
            <img src={circle} alt={''} className={style.circle20}/>
            <img src={circle} alt={''} className={style.circle21}/>
        </div>
    )
}
