import React, {useRef, useEffect} from 'react';
import style from './style.module.scss';
import cn from 'classnames';
import frame from "./phone.svg";

export default function PhoneVideo(props) {
    const {video, wrapperClass} = props;
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef && videoRef.current) {
            videoRef.current.play();
        }
    }, [videoRef]);

    return (
        <div className={cn(style.container, wrapperClass)}>
            <img src={frame} className={style.videoFrame} alt={'frame'}/>
            <video ref={videoRef} playsInline muted loop autoPlay className={style.video} controls={false}>
                <source src={video} type="video/mp4"/>
            </video>
        </div>
    )
}