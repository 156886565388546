import React from 'react';
import circle from './circle.svg';
import PhoneVideo from "../PhoneVideo";
import video from "./video.mp4";
import cn from 'classnames';
import style from './style.module.scss';

export default function AnimatedImage6({wrapperClass}) {
    return (
        <div className={cn(style.container, wrapperClass)}>
            <img src={circle} alt="img" className={style.circle}/>
            <PhoneVideo wrapperClass={style.video} video={video}/>
        </div>
    )
}