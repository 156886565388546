import React from 'react';
import cn from 'classnames';
// import Bubbles from '../Landing/Bubbles';
import logo from './img/logo.png';
import bubble1 from './img/1.png';
import bubble2 from './img/2.png';
import sm from './img/sm.png';
import md from './img/md.png';
import lg from './img/lg.png';

import style from './style.module.scss';

export default function ComingSoon() {
  return (
    <div className={cn(style.container, 'content')}>
      <div className={style.content}>
        <img src={bubble2} className={style.bubble1} alt="bubble"/>
        <img src={sm} className={style.sm} alt="bubble"/>
        <img src={md} className={style.md} alt="bubble"/>
        <img src={lg} className={style.lg} alt="bubble"/>
        <img src={logo} alt="logo" className={style.logo}/>
        <h2 className={style.heading}>Nafter Pools Coming Soon</h2>
        <p className={style.detail}>We're currently building out Pools. <br /> Check back soon.</p>
        <img src={bubble1} className={style.bubble2} alt="bubble" />
        <img src={sm} className={style.sm2} alt="bubble"/>
        <img src={md} className={style.md2} alt="bubble"/>
        <img src={lg} className={style.lg2} alt="bubble"/>
      </div>
    </div>
  );
}
