import React from 'react';
import cn from 'classnames';
import {SPONSORS_NEW} from "./constants";

import style from './style.module.scss';

export default function BrandList({wrapperClass}) {

    return (
        <ul className={cn(style.list, wrapperClass)}>
            {SPONSORS_NEW.map((item, i) => (
                <li className={style.item} key={i}>
                    <img className={style.image} src={item.image} alt=""/>
                </li>
            ))}
        </ul>
    )
}
