import React, {useEffect, useContext} from 'react';
import cn from 'classnames';
import lottie from "lottie-web";
import desktopAnimation from "./animation/dataDesktop";
import mobileAnimation from "./animation/dataMobile";
import {AppContextType} from "../../context/context_types";
import ProportionalBox from "../../components/ProportionalBox";
import Button from "../../components/Button";

import nafterIcon from '../../assets/naft.png'
import tel from './img/tel.png';
import www from './img/www.png';

import style from './style.module.scss';

export default function Home() {
    const id = 'bg';
    const {isMobile} = useContext(AppContextType);

    useEffect(() => {
        let animation = lottie.loadAnimation({
            container: document.getElementById(id),
            animationData: isMobile ? mobileAnimation : desktopAnimation,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            name: 'animation',
        });

        animation.play();

        return () => {
            lottie.destroy('animation');
        }
    }, [isMobile]);

    return window.location.href = 'https://app.nafter.io';

    // eslint-disable-next-line no-unreachable
    return (
        <div className={cn(style.container, 'content')}>
            <div className={style.bgWrapper}>
                <div className={style.bgMask}/>
                <ProportionalBox wrapperClass={style.bgBox} whProportion={isMobile ? 0.46 : 1.8}>
                    <div id={id} className={style.bg}/>
                </ProportionalBox>
            </div>
            <div className={style.content}>
                <h1 className={style.heading}>
                    <img src={nafterIcon} className={style.naft} alt=""/>
                    nafter Global Launch
                    <br/>
                    September 3<sup className={style.sup}>rd</sup> 2021
                </h1>
                <div className={style.actions}>
                    <Button
                        wrapperClass={style.action}
                        text={'Enter App'}
                        icon={www}
                        iconAlignStart
                        iconClass={style.actionIcon}
                        gradient
                        onClick={() => window.location.replace(process.env.REACT_APP_MARKETPLACE)}
                    />
                    <Button
                        wrapperClass={style.action}
                        text={'Telegram'}
                        icon={tel}
                        iconAlignStart
                        iconClass={style.actionIcon}
                        gradient
                        onClick={() => window.open('https://t.me/nafterapp', '_blank')}
                    />
                </div>
            </div>
        </div>
    );
}
