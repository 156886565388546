import React from 'react';
import particle from './1-1.png';
import particle4 from './circle.svg';
import PhoneVideo from "../PhoneVideo";
import video1 from "./2-1.mp4";
import cn from 'classnames';
import style from './style.module.scss';

export default function AnimatedImage2({wrapperClass}) {
    return (
        <div className={cn(style.container, wrapperClass)}>
            <img src={particle} alt="img" className={style.particle1}/>
            <img src={particle} alt="img" className={style.particle2}/>
            <img src={particle} alt="img" className={style.particle3}/>
            <img src={particle4} alt="img" className={style.circle}/>
            <PhoneVideo wrapperClass={style.video} video={video1}/>
        </div>
    )
}