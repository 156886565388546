import React from 'react';
import style from './style.module.scss';
import cn from 'classnames';
import Button from "../../../components/Button";
import bg from './videobg.png';
import approved from './approved.png';
import naft from './naft.png';

export default function AmbassadorBanner({wrapperClass}) {
    return (
        <section className={cn(style.container, wrapperClass)}>
            <a href="https://youtu.be/tfWMzCZuPI8" target={'blank'}>
                <div className={style.bg}>
                    <div className={style.image} style={{backgroundImage: `url(${bg})`}}/>
                </div>
            </a>
            <div className={style.content}>
                <div className={style.short}>
                    <img src={naft} className={style.avatar} alt=""/>
                    <div className={style.info}>
                        <h4 className={style.name}>
                            Nafter Tutorial <img src={approved} className={style.approved} alt=""/>
                        </h4>
                        <p className={style.description}>
                            Watch our Official Nafter Video.
                        </p>
                    </div>
                </div>
                <div className={style.actions}>
                    <Button
                        wrapperClass={style.button}
                        text={'Watch Video'}
                        iconAlignStart
                        compact
                        gradient
                        round
                        onClick={() => window.open('https://youtu.be/tfWMzCZuPI8', '_blank')}
                    />
                </div>
            </div>
        </section>
    )
}
