import React, { PureComponent } from 'react';
import { HEADER_NAVIGATION_ITEMS } from './constants';
import Button from '../../Button';
import cn from 'classnames';
import { AppContextType } from '../../../context/context_types';
import { PAGE_HOME_PATH, PAGE_POOLS_PATH } from '../../../router/constants';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../../assets/logo.png';
import pancakeswapIcon from './pancakeswap.png';
import Hamburger from './Hamburger';

import style from './style.module.scss';
// import btnIcon from './btn-icon.png';


class Header extends PureComponent {
    state = {
        scrolled: false
    };

    componentDidMount() {
        this.handleScroll();

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (e) => {
        const { scrolled } = this.state;

        if (!scrolled && window.scrollY > 1) {
            this.setState({ scrolled: true });
        } else if (scrolled && window.scrollY < 1) {
            this.setState({ scrolled: false });
        }
    };

    render() {
        const { wrapperClass, menuOpen, location, isMobile, onToggleOpen } = this.props;
        const { scrolled } = this.state;
        const poolsPage = location.pathname === PAGE_POOLS_PATH;

        return (
            <header className={cn(style.box, wrapperClass, {
                [style.boxScrolled]: scrolled,
                [style.boxOpen]: menuOpen,
                [style.poolsPage]: poolsPage
            })}>
                <div className={cn(style.content, 'content')}>
                    <Link
                        className={style.logoLink}
                        to={PAGE_HOME_PATH}
                    >
                        <img className={style.logo} src={logo} alt={'logo'} />
                    </Link>
                    <nav id={'headerNav'} className={style.menuWrapper}>
                        <ul className={style.menu}>
                            {HEADER_NAVIGATION_ITEMS.map((item, i) => (
                                <li
                                    className={cn(style.menuItem, {
                                        [style.menuItemActive]: location.pathname === item.path
                                    })}
                                    key={i}
                                >
                                    {item.href ? (
                                        <a href={item.href} className={style.menuLink} rel={'noreferrer'}>
                                            {item.name}
                                        </a>
                                    ) : (
                                        <Link className={style.menuLink} to={item.path}>
                                            {item.name}
                                        </Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </nav>
                    {
                        isMobile ? (
                            <Hamburger
                                isOpen={menuOpen}
                                onClick={onToggleOpen}
                            />
                        ) : (
                            <Button
                                wrapperClass={style.button}
                                text={'PancakeSwap'}
                                compact
                                gradient
                                icon={<img src={pancakeswapIcon} alt="ico" />}
                                iconAlignStart
                                iconClass={style.buttonIconWrapper}
                                onClick={() => window.open('https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xd7730681b1dc8f6f969166b29d8a5ea8568616a3', '_blank')}
                            />
                        )
                    }
                </div>
            </header>
        );
    }
}

Header.contextType = AppContextType;

export default withRouter(Header);
