import inst from './img/section11/instagram.png';
import tel from './img/section11/telegram.png';
import twit from './img/section11/twitter.png';
import med from './img/section11/medium.png';
import chart from './img/section11/717.png';
// import uniswap from './img/section11/718.png';
import avatar1 from './img/section7/12.jpg';
import avatar2 from './img/section7/2.webp';
// import avatar3 from './img/section7/3.webp';
// import avatar4 from './img/section7/4.webp';
import avatar5 from './img/section7/5.webp';
// import avatar6 from './img/section7/6.webp';
// import avatar7 from './img/section7/7.webp';
import avatar8 from './img/section7/8.webp';
import avatar9 from './img/section7/9.webp';
// import avatar10 from './img/section7/10.webp';
// import avatar11 from './img/section7/11.jpg';

import feature1 from './img/section8/naft.png';
import feature2 from './img/section8/1.png';
import feature3 from './img/section8/2.png';
import feature4 from './img/section8/3.png';

import tierIcon1 from './img/tiers/fanBoy.png';
import tierIcon2 from './img/tiers/celebrity.png';
import tierIcon3 from './img/tiers/influencer.png';
import tierChildIcon1 from './img/tiers/shape.png';
import tierChildIcon2 from './img/tiers/verified.png';
import tierChildIcon3 from './img/tiers/l0001.png';

export const FEATURES = [
  {
    icon: feature1,
    text: 'Stake NAFT on profiles\n to see exclusive content'
  },
  {
    icon: feature2,
    text: '0% fees on Nafter\n transactions'
  },
  {
    icon: feature3,
    text: 'Choose which content\n gets featured'
  },
  {
    icon: feature4,
    text: 'Vote for platform\n upgrades'
  }
];

export const ROAD_MAP = [
  {
    title: 'Q2',
    year: '2021',
    points: [
      'PancakeSwap Listing',
      'CEX Listing'
    ]
  },
  {
    title: 'Q3',
    year: '2021',
    points: [
      'Version 1 Global Launch',
      'Instagram Influencer Campaign'
    ]
  },
  {
    title: 'Q4',
    year: '2021',
    points: [
      'Profile Staking for Exclusive Content',
      'Top Staked Profile Rewards'
    ]
  }
];

export const CHART_DATA = [
  { name: 'Platform: 30%', value: 300000000, color: '#5dc2f8' },
  { name: 'Liquidity: 25%', value: 250000000, color: '#8a5eea' },
  { name: 'Sale: 20%', value: 200000000, color: '#d34bc2' },
  { name: 'Marketing: 15%', value: 150000000, color: '#ef8c84' },
  { name: 'Team: 10%', value: 100000000, color: '#f3c26e' }
];

export const SOCIAL_MEDIA = [
  { icon: tel, href: 'https://t.me/nafterapp' },
  { icon: twit, href: 'https://twitter.com/Nafterapp' },
  { icon: inst, href: 'https://www.instagram.com/nafterapp/' },
  { icon: med, href: 'https://nafterapp.medium.com/' },
  { icon: chart, href: 'https://bscscan.com/token/0xd7730681b1dc8f6f969166b29d8a5ea8568616a3#balances' }
  // {icon: uniswap, href: '/'},
];

export const TEAM = [
  { avatar: avatar1, name: 'Ani S', description: 'CEO', linkedin: '' },
  { avatar: avatar2, name: 'Viet L.', description: 'CTO', linkedin: 'https://www.linkedin.com/in/viet-le-van-thanh-b75ab8108/' },
  { avatar: avatar5, name: 'Dung P.', description: 'Full Stack Developer', linkedin: 'https://www.linkedin.com/in/phandung236/' },
  { avatar: avatar8, name: 'Asep S.', description: 'UI Designer', linkedin: 'https://www.linkedin.com/in/asep-saripudin-39b178141' },
  { avatar: avatar9, name: 'Lucky M.', description: 'Motion Artist', linkedin: 'https://www.linkedin.com/in/lucky-malikal-mulki-i-519354121/ ' },
];

export const TIERS = [
  { logo: tierIcon1, childIcon: [tierChildIcon1, tierChildIcon2, tierChildIcon3], title: 'Fanboy', staking: '5,000 NAFT', verify: 'No', apy: '~100% APY' },
  { logo: tierIcon2, childIcon: [tierChildIcon1, tierChildIcon2, tierChildIcon3], title: 'Influencer', staking: '50,000 NAFT', verify: 'No', apy: '~150% APY' },
  { logo: tierIcon3, childIcon: [tierChildIcon1, tierChildIcon2, tierChildIcon3], title: 'Celebrity', staking: '250,000 NAFT', verify: 'YES', apy: '~200% APY' }
];
