import React from 'react';
import particle1 from './1-1.png';
import particle2 from './1-2.png';
import particle3 from './1-3.png';
import particle4 from './1-4.svg';
import PhoneVideo from "../PhoneVideo";
import video1 from "./1-1.mp4";
import cn from 'classnames';
import style from './style.module.scss';

export default function AnimatedImage1({isMobile, wrapperClass}) {
    return (
        <div className={cn(style.container, wrapperClass)}>
            <img src={particle1} alt="img" className={style.particle1}/>
            <img src={particle2} alt="img" className={style.particle2}/>
            <img src={particle3} alt="img" className={style.particle3}/>
            <img src={particle4} alt="img" className={style.circle}/>
            <PhoneVideo wrapperClass={style.video} video={video1}/>
        </div>
    )
}