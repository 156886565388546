import React from 'react';
import particle1 from './1.webp';
import particle2 from './2.webp';
import particle3 from './3.webp';
import particle4 from './4.webp';
import circle from './circle.svg';
import PhoneVideo from "../PhoneVideo";
import video from "./video.mp4";
import cn from 'classnames';
import style from './style.module.scss';

export default function AnimatedImage3({wrapperClass}) {
    return (
        <div className={cn(style.container, wrapperClass)}>
            <img src={particle1} alt="img" className={style.particle1}/>
            <img src={particle2} alt="img" className={style.particle2}/>
            <img src={particle3} alt="img" className={style.particle3}/>
            <img src={particle4} alt="img" className={style.particle4}/>
            <img src={circle} alt="img" className={style.circle}/>
            <PhoneVideo wrapperClass={style.video} video={video}/>
        </div>
    )
}