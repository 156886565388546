import React from 'react';
import style from './style.module.scss';
import Header from './Header';
import Footer from './Footer';
import cn from 'classnames';
import Loader from './Loader';
import { withRouter } from 'react-router-dom';
import { AppContextType } from '../../context/context_types';
import { HEADER_NAVIGATION_ITEMS } from './Header/constants';
import Button from '../Button';
import Hamburger from './Header/Hamburger';
import pancakeswapIcon from './Header/pancakeswap.png';

class Layout extends React.PureComponent {
  state = {
    menuOpen: false
  };

  componentDidMount() {
    this.navigateToAnchor();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location } = this.props;

    if ((location !== prevProps.location)) {
      this.navigateToAnchor();
    }
  }

  navigateToAnchor = () => {
    const { location } = this.props;

    if (location.hash) {
      const el = document.querySelector(location.hash);

      if (el) {
        window.scrollTo({
          top: el.offsetTop - 200,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  };

  // handleMenuOpen = () => {};

  handleMenuOpen = () => {
    setTimeout(() => {
      const { menuOpen } = this.state;

      if (menuOpen) {
        window.addEventListener('click', this.handleMenuOuterClick);
      }
    }, 150);
  };

  handleMenuOuterClick = (e) => {
    const { menuOpen } = this.state;
    const dropDown = e.target.closest('#headerNav');

    if (!dropDown && menuOpen) {
      this.setState({ menuOpen: false });

      window.removeEventListener('click', this.handleMenuOuterClick);
    }
  };

  onMenuClick = item => {
    this.setState({ menuOpen: false }, () => {
      if (item.href) {
        window.open(item.href, '_blank')
      } else {
        this.props.history.push(item.path);
      }
    });
  };

  render() {
    const { menuOpen } = this.state;
    const { isReady, isMobile } = this.context;
    const { location } = this.props;

    return (
      <div className={style.box}>
        <Header
          isMobile={isMobile}
          menuOpen={menuOpen}
          onToggleOpen={() => this.setState({ menuOpen: !menuOpen })}
        />
        <main className={style.content}>{this.props.children}</main>
        <Footer location={location} />
        <Loader isVisible={!isReady} />
        <div className={cn(style.shadow, { [style.shadowVisible]: menuOpen })} onClick={() => this.setState({ menuOpen: false })} />
        <nav id={'headerNav'} className={cn(style.fade, {
          [style.fadeActive]: menuOpen
        })}>
          <div className={style.hamburgerWrapper}>
            <Hamburger wrapperClass={style.hamburger} isOpen={menuOpen} onClick={() => this.setState({ menuOpen: false })} />
          </div>
          <ul className={style.menu}>
            {HEADER_NAVIGATION_ITEMS.map((item, i) => (
              <li
                className={cn(style.menuItem)}
                key={i}
              >
                <div className={style.menuLink}>
                  <Button
                    wrapperClass={style.menuItemBtn}
                    text={item.name}
                    compact
                    gradient={location.pathname === item.path}
                    onClick={() => this.onMenuClick(item)}
                  />
                </div>
              </li>
            ))}
            <li
              className={style.menuItem}
            >
              <div className={style.menuLink}>
                <Button
                  wrapperClass={style.button}
                  text={'PancakeSwap'}
                  compact
                  gradient
                  icon={<img src={pancakeswapIcon} alt="ico" />}
                  iconAlignStart
                  iconClass={style.buttonIconWrapper}
                  onClick={() => window.open('https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xd7730681b1dc8f6f969166b29d8a5ea8568616a3', '_blank')}
                />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    );
  }

};

Layout.contextType = AppContextType;

export default withRouter(Layout);
