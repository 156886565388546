import React, {PureComponent} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {PAGE_HOME_PATH, PAGE_POOLS_PATH, PAGE_TERMS_PATH, PAGE_PRIVACY_PATH, PAGE_LANDING_PATH} from "./constants";
import Landing from "../pages/Landing";
import NotFound from "../pages/NotFound";
// import Pools from "../pages/Pools";
import PoolComingSoon from "../pages/PoolComingSoon";
import Privacy from "../pages/TermsAndPolicy/PrivacyPolicy";
import Terms from "../pages/TermsAndPolicy/Terms";
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import Home from "../pages/Home";

@withRouter
class Router extends PureComponent {
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;

        if (location.key !== prevProps.location.key) {
            window.scrollTo(0,0);
        }
    }

    render() {
        return (
            <Route
                render={({ location }) => (
                    <TransitionGroup>
                        <CSSTransition timeout={800} classNames={'fade'} key={location.pathname.split('/')[1] || '*'}>
                            <Switch>
                                <Route exact path={PAGE_HOME_PATH} component={Home} key={PAGE_HOME_PATH}/>
                                <Route exact path={PAGE_POOLS_PATH} component={PoolComingSoon} key={PAGE_POOLS_PATH}/>
                                <Route exact path={PAGE_PRIVACY_PATH} component={Privacy} key={PAGE_PRIVACY_PATH}/>
                                <Route exact path={PAGE_TERMS_PATH} component={Terms} key={PAGE_TERMS_PATH}/>
                                <Route exact path={PAGE_LANDING_PATH} component={Landing} key={PAGE_LANDING_PATH}/>
                                <Route component={NotFound} key="*" />
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                )}
            />
        )
    }
}

export default Router;
