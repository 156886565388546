import React, { useContext } from 'react';
import section1AppleIcon from './img/section1/001-apple.png';
import section1AndrIcon from './img/section1/002-android-logo.png';
import section1WorldIcon from './img/section1/003-world-wide-web.png';
import section2Bg from './img/section2bg.webp';
import naft from './img/section8/naft.png';
// import arrow from './img/section9/arrow.png';
// import ellipse from './img/section9/ellipse.png';
import section11Bg from './img/section11/bg.webp';
import section11BgMobile from './img/section11/bgmobile.webp';
import logo from '../../assets/logo.png';
import telegram from './img/section1/tel.png';
import chart from './img/chart.png';
import cn from 'classnames';
import { CHART_DATA, FEATURES, /* ROAD_MAP, */ SOCIAL_MEDIA, /* TEAM, TIERS */ } from './constants';
import { AppContextType } from '../../context/context_types';
import formatNumber from '../../utils';
import AnimatedImage1 from './AnimatedImage1';
import AnimatedImage2 from './AnimatedImage2';
import AnimatedImage3 from './AnimatedImage3';
import AnimatedImage4 from './AnimatedImage4';
import AnimatedImage5 from './AnimatedImage5';
// import AnimatedAvatar from './AnimatedAvatar';
import Bubbles from './Bubbles';
import Button from '../../components/Button';
// import { useIntersectionObserver } from '../../hooks';
import AnimatedImage6 from './AnimatedImage6';
// import IdoBanner from "./IdoBanner";
// import pool from './img/pool.webp';
import AmbassadorBanner from './AmbassadorBanner';
// import linkedin from './img/section7/linkedin.svg';

import style from './style.module.scss';
import BrandList from './BrandList';
import Layout from "../../components/Layout";

export default function Landing(props) {
    const { isMobile } = useContext(AppContextType);
    // const teamRef = useRef(null);
    // const isIntersecting = useIntersectionObserver(teamRef);

    return (
        <Layout>
            <div className={style.container}>
                <Bubbles />
                <section id={'home'} className={cn(style.section1, 'content')}>
                    <div className={style.section1Left}>
                        <h1 className={style.section1Heading}>
                            {'Where Content\n Becomes Collectible'}
                        </h1>
                        <p className={style.section1Text}>
                            {
                                'Introducing Nafter, an online marketplace for\n ' +
                                'creators and fans to buy, sell, mint & collect\n ' +
                                'content NFTs — all in one web app.'
                            }
                        </p>
                        <div className={style.section1MobileOnly}>
                            <AnimatedImage1 wrapperClass={style.section1Picture} />
                        </div>
                        <Button
                            wrapperClass={style.section1Button}
                            iconClass={style.section1ButtonIcon}
                            text={'Join Telegram'}
                            icon={telegram}
                            iconAlignStart
                            compact
                            gradient
                            outline
                            onClick={() => window.open('https://t.me/nafterapp', '_blank')}
                        />
                        {
                            /*
                            <div className={style.sectionButtons}>
                            <Button
                              wrapperClass={style.section1Button}
                              iconClass={style.section1ButtonIcon}
                              text={'Join Telegram'}
                              icon={telegram}
                              iconAlignStart
                              compact
                              gradient
                              outline
                              onClick={() => window.open('https://t.me/nafterapp', '_blank')}
                            />
                            <Button
                              wrapperClass={style.section2Button}
                              text={'Lite Paper'}
                              compact
                              gradient
                              outline
                              onClick={() => window.open('https://nafter.io/docs/Nafter-Litepaper-V.1.pdf', '_blank')}
                            />
                        </div>
                            * */
                        }

                        <div className={style.section1Icons}>
                            <img src={section1AppleIcon} alt="" className={style.section1Icon} />
                            <img src={section1AndrIcon} alt="" className={style.section1Icon} />
                            <img src={section1WorldIcon} alt="" className={style.section1Icon} />
                        </div>
                    </div>
                    <div className={style.section1Right}>
                        <AnimatedImage1 wrapperClass={style.section1Picture} />
                    </div>
                </section>

                {/*<section className={cn(style.sectionIdo, 'content')}>*/}
                {/*    <h2 className={cn(style.sectionIdoHeading)}>*/}
                {/*        Dual IDO on May 6th, 2021*/}
                {/*    </h2>*/}
                {/*    <IdoBanner wrapperClass={style.sectionIdoBanner}/>*/}
                {/*</section>*/}

                {/*<section className={cn(style.row, style.sectionPool, 'content')}>*/}
                {/*    <div className={cn(style.content, style.sectionPoolContent)}>*/}
                {/*        <h2 className={style.sectionPoolHeading}>*/}
                {/*            {'Introducing\n'} <img src={naft} className={style.sectionPoolIcon} alt=""/> Nafter Pools*/}
                {/*        </h2>*/}
                {/*        <p className={style.sectionPoolText}>*/}
                {/*            {*/}
                {/*                'Simply choose a pool, stake your NAFT tokens & earn NAFT \n' +*/}
                {/*                'tokens as reward for staking.'*/}
                {/*            }*/}
                {/*        </p>*/}
                {/*        <Button*/}
                {/*            wrapperClass={style.sectionPoolButton}*/}
                {/*            text={'Stake NAFT'}*/}
                {/*            compact*/}
                {/*            gradient*/}
                {/*            outline*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className={cn(style.image, style.sectionPoolImageWrapper)}>*/}
                {/*        <img src={pool} className={style.sectionPoolImage} alt=""/>*/}
                {/*    </div>*/}
                {/*</section>*/}

                <section id={'features'} className={cn(style.row, style.section2, 'content')}>
                    <div className={style.section2Left}>
                        <img src={section2Bg} alt="" className={style.section2Image} />
                    </div>
                    <div className={style.content}>
                        <h2 className={style.heading}>Mint. Sell. Collect.</h2>
                        <p className={style.text}>
                            {
                                'Take a photo from your smartphone, "mint" it to an Non-fungible\n ' +
                                'token(NFT), post your newly minted NFT to Nafter, then share &\n ' +
                                'sell them to your biggest fans.'
                            }
                        </p>
                    </div>
                </section>
                <section className={cn(style.row, style.section3, 'content')}>
                    <div className={style.content}>
                        <h2 className={style.heading}>{'Monetize Media\n in Minutes'}</h2>
                        <p className={style.text}>
                            {
                                'Post a photo to Nafter and it becomes a unique NFT,\n ' +
                                'a collectable to sell to your fans in the app. The more\n ' +
                                'fans you have, the more your content pays.'
                            }
                        </p>
                    </div>
                    <div className={style.image}>
                        <AnimatedImage2 wrapperClass={style.section3Picture} />
                    </div>
                </section>
                <section className={cn(style.row, style.section12, 'content')}>
                    <div className={style.image}>
                        <AnimatedImage6 wrapperClass={style.section12Picture} />
                    </div>
                    <div className={style.content}>
                        <h2 className={style.heading}>{'Stake to Unlock\nExclusive Content'}</h2>
                        <p className={style.text}>
                            {
                                'By staking on a creator’s profile, you will gain access \n' +
                                'to exclusive content by that creator, while also being \n' +
                                'able to earn additional NAFT tokens.'
                            }
                        </p>
                    </div>
                </section>
                <section className={cn(style.row, style.section5, 'content')}>
                    <div className={style.content}>
                        <h2 className={style.heading}>Shop Seamlessly</h2>
                        <p className={style.text}>
                            {
                                'Purchasing content and staking on profiles is\n ' +
                                'only a tap away by using your Metamask wallet'
                            }
                        </p>
                    </div>
                    <div className={style.image}>
                        <AnimatedImage4 wrapperClass={style.section5Picture} />
                    </div>
                </section>
                <section className={cn(style.row, style.section4, 'content')}>
                    <div className={style.image}>
                        <AnimatedImage3 wrapperClass={style.section4Picture} />
                    </div>
                    <div className={style.content}>
                        <h2 className={style.heading}>{'Control Who Sees\n Your Content'}</h2>
                        <p className={style.text}>
                            {
                                'Customize who can see your NFTs. You can make\n' +
                                ' posts public, or for collectors/stakers only.'
                            }
                        </p>
                    </div>
                </section>
                <section className={cn(style.row, style.section6, 'content')}>
                    <div className={cn(style.content, style.section6Content)}>
                        <h2 className={style.heading}>{'Discover the World\n of NFTs'}</h2>
                        <p className={style.text}>
                            {
                                'Find new NFT photos in creators’ feeds, or discover\n ' +
                                'what’s trending in the community-curated Explorer.'
                            }
                        </p>
                    </div>
                    <div className={style.image}>
                        <AnimatedImage5 wrapperClass={style.section6Picture} />
                    </div>
                </section>

                {/* <section id={'tiers'} className={style.section7}>
                    <div className="content">
                        <h2 className={style.section7Heading}>
                            {'Nafter Pool Tiers'}
                        </h2>
                        <p className={style.section8Text}>
                            {
                                'Nafter uses a tier based approach to reward\n' +
                                'its members in the staking pools.'
                            }
                        </p>
                        <ul className={style.tiersList}>
                            {TIERS.map((item, i) => (
                                <li className={style.tiersListItem} key={i}>
                                    <img src={item.logo} alt="" className={style.imgLogo} />
                                    <p className={style.title}>{item.title}</p>
                                    <div className={style.block}>
                                        <div className={style.blockIcon}>
                                            <img src={item.childIcon[0]} alt="" />
                                        </div>
                                        <div className={style.blockContent}>
                                            <p className={cn(style.section8Text, style.contentText)}>Staking Requirement</p>
                                            <p className={cn(style.section7Heading, style.contentValue)}>{item.staking}</p>
                                        </div>
                                    </div>
                                    <div className={style.block}>
                                        <div className={style.blockIcon}>
                                            <img src={item.childIcon[1]} alt="" />
                                        </div>
                                        <div className={style.blockContent}>
                                            <p className={cn(style.section8Text, style.contentText)}>Verified Badge</p>
                                            <p className={cn(style.section7Heading, style.contentValue)}>{item.verify}</p>
                                        </div>
                                    </div>
                                    <div className={style.block}>
                                        <div className={style.blockIcon}>
                                            <img src={item.childIcon[2]} alt="" />
                                        </div>
                                        <div className={style.blockContent}>
                                            <p className={cn(style.section8Text, style.contentText)}>Pool APY %</p>
                                            <p className={cn(style.section7Heading, style.contentValue)}>{item.apy}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div style={{ textAlign: 'center' }}>
                            <a href={'https://staking.nafter.io'} style={{ textDecoration: 'unset' }}>
                                <Button
                                    wrapperClass={style.tiersBtn}
                                    text={'Nafter Pools'}
                                    compact
                                    gradient
                                />
                            </a>
                        </div>
                    </div>
                </section> */}

                <section className={cn(style.sectionAmbassador, 'content')}>
                    <h2 className={style.sectionAmbassadorHeading}>
                        <img className={style.sectionAmbassadorIcon} src={naft} alt="" />Nafter Tutorial
                    </h2>
                    <AmbassadorBanner wrapperClass={style.sectionAmbassadorBanner} />
                </section>
                {/* <section id={'team'} className={style.section7}>
                    <div className="content">
                        <h2 className={style.section7Heading}>
                            {'Meet the Team \n Bringing NFTs to the Masses'}
                        </h2>
                        <ul ref={teamRef} className={style.section7List}>
                            {TEAM.map((item, i) => (
                                <li className={style.section7ListItem} key={i}>
                                    <AnimatedAvatar
                                        image={item.avatar}
                                        wrapperClass={style.section7Avatar}
                                        triggerAnimation={isIntersecting}
                                    />
                                    <strong className={style.section7Name}>{item.name}</strong>
                                    <p className={style.section7Description}>{item.description}</p>
                                    <a href={item.linkedin} target={'_blank'} rel={'noreferrer'}>
                                        <img src={linkedin} alt="" className={style.section7Icon} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section> */}
                <section id={'naft'} className={style.section8}>
                    <div className="content">
                        <img src={naft} className={style.section8Image} alt="" />
                        <h2 className={style.section8Heading}>
                            Introducing NAFT <br />
                            Governance Token
                        </h2>
                        <p className={style.section8Text}>
                            Nafter empowers creators & clientele by allowing them to align platform development to their best interests.
                        </p>
                        <h3 className={style.section8SubHead}>
                            How to use NAFT
                        </h3>
                        <ul className={style.section8Features}>
                            {FEATURES.map((item, i) => (
                                <li className={style.section8Feature} key={i}>
                                    <img src={item.icon} className={style.section8FeatureImage} alt="" />
                                    <strong className={style.section8FeatureText}>
                                        {item.text}
                                    </strong>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
                <section className={style.section10}>
                    <div className="content">
                        <h2 className={style.section10Heading}>
                            Token Distribution
                        </h2>
                        <p className={style.section10Text}>Total Supply: 1,000,000,000</p>
                        <div className={style.section10ChartWrapper}>
                            {/*<Chart data={CHART_DATA} wrapperClass={style.section10Chart} />*/}
                            <img src={chart} className={style.section10Chart} alt={''} />
                            <ul className={style.section10Legend}>
                                {CHART_DATA.map((item, i) => (
                                    <li className={style.section10LegendItem} key={i}>
                                        <span className={style.section10Value}>
                                            {formatNumber(item.value, '', 0)}
                                        </span>
                                        <div className={style.section10Color} style={{ backgroundColor: item.color }} />
                                        <strong className={style.section10Name}>{item.name}</strong>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
                <section className={cn(style.sectionBrands, 'content')}>
                    <h2 className={style.sectionBrandsHeading}>Investors & Partners</h2>
                    <p className={style.sectionBrandsText}>Meet the VC Funds backing Nafter.</p>
                    <BrandList wrapperClass={style.sectionBrandsList} />
                </section>
                {/* <section className={style.section9}>
                    <h2 className={style.section9Heading}>
                        Roadmap
                    </h2>
                    <div className={style.section9Map}>
                        {ROAD_MAP.map((item, i) => (
                            <div className={style.section9MapItem} key={i}>
                                <div className={style.section9MapHead}>
                                    <h3 className={style.section9MapHeading}>
                                        {item.title}
                                    </h3>
                                    <span className={style.section9Year}>{item.year}</span>
                                    <div className={style.section9Line} />
                                    <img src={arrow} className={style.section9MapArrow} alt={''} />
                                </div>
                                <ul className={style.section9MapBody}>
                                    {item.points.map((point, i) => (
                                        <li className={style.section9MapPoint} key={i}>
                                            <img src={ellipse} className={style.section9MapBullet} alt="" /> {point}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </section> */}
                <section className={style.section11}>
                    <div className="content content_level_1">
                        <div className={style.section11Card}>
                            <img src={isMobile ? section11BgMobile : section11Bg} className={style.section11Bg} alt={''} />
                            <div className={style.section11Info}>
                                <div className={style.section11InfoContent}>
                                    <h2 className={style.section11Heading}>
                                        Stay Informed About <img className={style.section11Logo} src={logo} alt="nafter" />
                                    </h2>
                                    <p className={style.section11Text}>
                                        Learn more about Nafter by joining our communities.
                                    </p>
                                    <ul className={style.section11List}>
                                        {SOCIAL_MEDIA.map((item, i) => (
                                            <li className={style.section11ListItem} key={i}>
                                                <a className={style.section11Link} href={item.href} target='_blank' rel="noreferrer">
                                                    <img className={style.section11Icon} src={item.icon} alt={''} />
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}
