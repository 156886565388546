import React from 'react'
import cn from 'classnames';
import style from './style.module.scss';
import Layout from "../../components/Layout";

export default function NotFound() {
    let pathname = window.location.pathname;

    if(pathname.charAt(0) === '/')
    {
        pathname = pathname.substring(1);
    }

    if (pathname.match(/^[a-z0-9_-]{4,20}$/)) {
        if (pathname === 'landing' ) {
          window.location.replace(`https://app.nafter.io/404`);
        } else {
          window.location.replace(`https://app.nafter.io/profile/${pathname}`);
        }
    }

    return (
        <Layout>
            <div className={cn(style.container, 'content content_level_2')}>
                <h1 className={style.heading}>
                    404
                </h1>
                <h3 className={style.strong}>NOT FOUND</h3>
                <p className={style.text}>
                    The page you are looking for can't be found
                </p>
            </div>
        </Layout>
    )
}
