import React from 'react';

import style from './style.module.scss';

function Terms() {
  return (
    <div className={style.content}>
      <h1>Privacy is our priority.</h1>
      <p>We are committed to protecting our customers' personal information. In the course of providing financial products and services, we need to collect and maintain certain personal information. This Privacy Policy answers some questions about what personal information we collect and why, what we do with it, and how we protect it.</p>
      <h4>What types of personal information do we collect?</h4>
      <p>When you apply for, open, or maintain an account with us, or when you agree to allow us to provide personalized advisory services, we collect the following types of information:</p>

      <p>&nbsp;Information you provide online and on applications or other forms, or through discussions we have with you or your representatives, such as your name, address, date of birth, investment experience, risk tolerance, and any information about your personal investment goals or objectives. Information about your transactions with or through us, such as your account balance, trading activity, and account history, if applicable.</p>

      <h4>Why Do We Collect Personal Information?</h4>
      <p>We use and disclose personal information for the purposes that we have disclosed to you, except as otherwise permitted by law. We will request your consent before we use or disclose your information for any materially different purpose. Unless specifically described below, consent may be obtained in any legally sufficient method. For example, depending on the circumstance and the laws of your jurisdiction, consent may be obtained by providing you with notice and the opportunity to opt-out.</p>
      <p>&nbsp;</p>

      <p>In general, we collect, use and disclose personal information to meet the needs of our customers and for our business purposes, including:</p>

      <ul>
        <li> to provide the products and services you request;</li>
        <li> the day-to-day operation and maintenance of accounts and services;</li>
        <li> collection of amounts outstanding from you;</li>
        <li> to tell you about services or other related products and services offered by us;</li>
        <li> to manage our websites and services;</li>
        <li> to understand our customers and their needs;</li>
        <li> to learn about our markets and design and improve our services and related products;</li>
        <li> to administer and process any request for information or job application;</li>
        <li> to comply with our regulatory and legal obligations, including but not limited to warrants, subpoenas and court orders or to meet government tax reporting requirements;</li>
        <li> to contact you (including by way of e-mail), including:</li>
        <li> in response to your inquiries and comments, and to safeguard your interests;</li>
        <li> to provide you with information about our products and services, or those of others, that you may be interested in;</li>
        <li> to investigate suspicious activities; and</li>
        <li> to protect our rights and property.</li>
      </ul>

      <p>We may collect personal information from visitors to our websites and individuals who participate in a promotion, contest or survey (including online or via telephone). We may use this information to advise them of products, services and other marketing materials which may be of interest to them. We may also invite visitors to participate in market research, surveys and similar activities.</p>

      <p>In the event we propose to buy or sell any business or assets, we may disclose your information in an anonymized form. This information would only be disclosed to a prospective buyer or seller and would also be anonymized. In the event of an acquisition or merger we will give you notice if, and before, there is any transfer of information.</p>

      <h4>Where do we store personal information?</h4>
      <p>Generally, the personal information that we collect from you will be stored in the United States and other countries, which may have different privacy laws and standards than those in your country of residence.</p>

      <p>It is important to note that some or all of your personal information provided to third party service providers may be held in countries other than your own, including in particular the United States. You acknowledge and understand that your personal information will be subject to the applicable laws of each such jurisdiction, which may not provide for the same protection as your country of residence. For example, government entities in the United States and other countries may have certain legislative rights to access your personal information. If you have questions about our policies and practices with respect to service providers outside your jurisdiction, including the collection, use, disclosure or storage of such personal information by our service providers worldwide, you may contact our Data Protection Department by e-mail at info@nafter.io.</p>

      <p>Information you provide online and on applications or other forms, or through discussions we have with you or your representatives, such as your name, address, date of birth, investment experience, risk tolerance, and any information about your personal investment goals or objectives. Information about your transactions with or through us, such as your account balance, trading activity, and account history, if applicable.</p>

      <p>Individual Rights for European Economic Area Residents under GDPR (General Data Protection Regulation)</p>
      <p>The GDPR regulation provides rights for individuals (data subjects):</p>
      <ul>
        <li>Right of Access</li>
        <li>Right to Rectification of Erasure Procedure</li>
        <li>Right to Object</li>
        <li>Right to Data Portability</li>
      </ul>
      <p>If case you wish to exercise any of those rights, please contact our Data Protection Department at info@nafter.io or using the contact form.</p>

      <h4>How do we protect the confidentiality and security of personal information?</h4>
      <p>We maintain reasonable physical, electronic, and procedural safeguards to protect your personal information. We place your account information on the secure portion of our website, using firewalls and other security technology to protect our network and systems from external attacks, and we require you to enter a unique user name and password to access your account information online. Our servers are enabled with Secure Sockets Layer (SSL) technology to prevent unauthorized parties from viewing the personal information that you provide or access during a secure session (look for the padlock icon on your browser). In addition, if you access information online, we use digital certificate services to authenticate that you are transacting with our website and not the website of an impostor.</p>

      <p>If EU data is transferred outside the European Economic Area, we will ensure that the receiving party is in full compliance with General Data Protection Regulation (GDPR) rules through contractual clauses.</p>

      <p>Our employees and third party service providers have access to your personal information only on a "need to know" basis. We conduct regular internal audits of our business practices and procedures in order to protect your personal information.</p>

      <h4>How long do we keep your personal data for?</h4>
      <p>Your personal data will be stored for the duration of your active Nafter account for general usage of the site and communication purposes.</p>

      <p>If your account falls into inactive status, we will dispose of your data within 90 days. In some cases we may keep a hash of your e-mail address to prevent repeated usage of the free trial.</p>

      <p>Use of automated decision-making and profiling</p>
      <p>In some cases, your data may be used to create a "profile" in an automated fashion. This profile information may be provided to third parties for analysis in order to provide you with a better customer experience.</p>

      <h4>How can customers access and update their personal information or correct any inaccuracy?</h4>
      <p>As a customer, you can access and update certain personal information that we collect about you. You may log on to our website using your user name and password. From the home page, click on you user name in the upper right and select the Account tab.</p>

      <p>We shall make every reasonable effort to maintain your personal information in an accurate, complete and up-to-date form. If you believe that your personal information is inaccurate, incomplete or out-of-date, you may request a correction at any time. Unless we have your personal information in our capacity as a third-party administrative record keeper, when you successfully demonstrate that your personal information is inaccurate or incomplete, we will correct or update the information as required.</p>

      <p>Please note that before we are able to provide you with any information or correct any inaccuracies we may ask you for additional information to verify your identity. We will only use this information to verify your identity.</p>

      <p>If you wish to have your data deleted please feel free to send a deletion request to our Data Protection Department at info@nafter.io and we will respond to your request within 28 days.</p>

      <h4>Children's Privacy</h4>
      <p>Our website is not targeted to children under the age of 13 and we do not intentionally collect personal information from children under the age of 13. If we discover that a child under the age of 13 has provided us with personal information through this website, we will take reasonable steps to delete the information. If you believe we may have accidentally received personal information from a child under the age of 13, please contact us immediately.</p>

      <h4>What should I know about "cookies"?</h4>
      <p>"Cookies" are small amounts of data that a website can send to your web browser and store on your computer. Our cookies do not contain personal information about you. Nafter will not store identifying personal information in website cookies.</p>

      <p>You do have control over cookies. Most Web browsers can be set to notify you when an HTML cookie is placed on your computer. You can manually delete HTML cookies and set most browsers to refuse to accept HTML cookies, although doing so may affect your website experience.</p>

      <h4>Linking to other websites</h4>
      <p>In many cases, Nafter may provide an external link to an outside exchange or other website. This link does not constitute an endorsement. As of this writing, we do not serve ads to our users, we do not promote third party websites or apps, nor do we intend to. We do our best to remain an agnostic, data-centric platform that is free of bias.</p>

      <h4>Personal data breach policy</h4>
      <p>Data breaches may be caused by employees, parties external to the organization, or computer system errors. Nafter is legally required to notify affected individuals if their personal data has been breached. This will encourage individuals to take preventive measures to reduce the impact of the data breach. We will notify affected individuals within 72 hours if a data breach involves sensitive personal data. In addition we will notify users as soon as the data breach is resolved. Information will be officially communicated via e-mail and our blog.</p>

      <h4>Updates to This Policy</h4>
      <p>Nafter may amend this policy from time to time. The revisions will take effect on the date of publication of the amended policy, as stated. We will notify you through our website of any material changes to the policy. In the event that the changes we make to the policy are substantial or affect the personal information we have already collected in accordance with this policy, you will be entitled to withdraw your consent to such personal information handling practices provided such consent is not necessary for us to provide to you the services or products you have requested.</p>
    </div>
  );
}

export default Terms;
