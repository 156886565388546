import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss';
import cn from 'classnames';
import logo from '../../../assets/logo.png';
import { FOOTER_NAVIGATION_ITEMS } from './constants';

const HIDE_FOOTER_PATH = ['/pools'];

export default function Footer(props) {
  if (HIDE_FOOTER_PATH.includes(props.location.pathname)) {
    return <div />;
  }
  return (
    <footer className={cn(style.box, 'content')}>
      <div className={style.empty} />
      <div className={style.content}>
        <img className={style.logo} src={logo} alt={'logo'} />
        <span className={style.copy}>Copyright 2021 by Nafter</span>
      </div>
      <div className={style.links}>
        {FOOTER_NAVIGATION_ITEMS.map((item, i) => (
          <Link key={i} className={style.itemLink} to={item.path}>
            {item.name}
          </Link>
        ))}
      </div>
    </footer>
  );
}
