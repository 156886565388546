import image1 from './img/1.webp';
import image2 from './img/2.webp';
import image3 from './img/3.webp';
import image4 from './img/4.webp';
import image5 from './img/5.webp';
import image6 from './img/6.webp';
import image7 from './img/7.webp';
import image8 from './img/8.webp';
import image9 from './img/9.webp';
import image10 from './img/10.webp';
import image11 from './img/11.webp';
import image12 from './img/12.webp';
import image13 from './img/13.webp';
import image14 from './img/14.webp';
import image15 from './img/15.webp';
import image16 from './img/16.png';
import image17 from './img/17.png';
import image18 from './img/18.png';
import image19 from './img/19.png';

export const SPONSORS = [
    {image: image1, href:'/'},
    {image: image2, href:'/'},
    {image: image3, href:'/'},
    {image: image4, href:'/'},
    {image: image5, href:'/'},
    {image: image6, href:'/'},
    {image: image7, href:'/'},
    {image: image8, href:'/'},
    {image: image9, href:'/'},
    {image: image10, href:'/'},
    {image: image11, href:'/'},
    {image: image12, href:'/'},
    {image: image13, href:'/'},
    {image: image14, href:'/'},
    {image: image15, href:'/'},
];

export const SPONSORS_NEW = [
    {image: image16, href:'/'},
    {image: image17, href:'/'},
    {image: image18, href:'/'},
    {image: image19, href:'/'},
];
